@font-face {
    font-family: 'Heldane';
    src: url(./fonts/test-heldane-display-regular.woff);
}

@font-face {
    font-family: 'Basier Circle';
    src: url(./fonts/BasierCircle-Regular.otf);
}

:root {
    --dark: #203FB5;
    --mid: rgb(24, 24, 196);
    --light: rgb(233, 236, 247);
    --text: #292456;
    --grad-left: #202ab510;
    --grad-right: #7b7ff62c;
    --cs-grad-left: #BA55D333;
    --cs-grad-right: #7E3ADA15;

    --font-body: 'Basier Circle', Arial, Helvetica, sans-serif;
    --font-h: meno-display-extra-condensed, serif;
  }

* {
margin: 0;
}

body {
-webkit-font-smoothing: antialiased;
}

img, picture, video, canvas, svg {
display: block;
max-width: 100%;
}


input, button, textarea, select {
font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
overflow-wrap: break-word;
}

ul {
list-style: circle;
}

ul, ol {
padding-inline-start: 24px;
margin-block-start: 12px;
line-height: inherit;
}

html, body {
    font-size: .9rem;
    line-height: 115%;
    font-family: var(--font-body);
    color: var(--text);
    background-color: #f4f5f2;
    overflow-x: hidden;
}

a {
    color: var(--text);
    text-decoration: underline;
    text-decoration-color: var(--mid);
}

a:hover {
    color: var(--mid);
}

body {
    scrollbar-color: var(--light) #7d93ed);
    scrollbar-width: thin;
    scrollbar-height: thin;
  }

::-webkit-scrollbar {
    background-color: var(--light);
    width: 8px;
    height: 0;
}
  
::-webkit-scrollbar-thumb {
    background-color: #7d93ed;
}

/* WRAPPERS */

.wrapper {
    width: auto;
    display: grid;
    grid-template-columns: 1fr minmax(auto, 2fr) minmax(auto, 3fr)  1fr;
    grid-template-rows: 1fr auto 1fr;
    grid-template-areas:
        ". info proj ."
        ". shea proj ."
        ". writing proj ."
    ;
    margin: 64px 24px 48px 64px;
    gap: 12px 0;
}

.projects {
    grid-area: proj;
    margin-block-end: 0;
    margin-left: 48px;
    height: fit-content;
}

.info {
    grid-area: info;
}

.writing {
    grid-area: writing;
    display: flex;
    flex-direction: column;
    justify-items: baseline;
    margin-block-start: auto;
}


.shea {
    grid-area: shea;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.today {
    grid-area: today
}

p.today {
    margin-top: 12px;
}

.shea img {
    max-width: 60%;
    max-height: 100%;
    border-radius: 100%;
    filter: drop-shadow(0 0 20px rgba(24, 24, 196, .25)) blur(17px);
    border: .5px solid var(--mid);
}

a.shea > img:hover {
   filter: drop-shadow(0 0 20px rgba(24, 24, 196, .25)) blur(0);
   cursor: pointer;
}

div.banner {
    font-family: var(--font-h);
    font-size: 1rem;
    color: var(--text);
    font-style: italic;
    position: absolute;
    height: 25px;
    z-index: 1000;
    mix-blend-mode: difference;
    pointer-events: none;
}


.footer {
    font-family: var(--font-h);
    display: flex;
    justify-content: center;
    font-style: italic;
    margin-bottom: 24px;
    color: var(--text);
    font-size: .875rem;
    letter-spacing: .1px;
}


.header {
    text-transform: uppercase;
    padding: 16px 16px 16px 0px;
    font-size: 1rem;
    align-content: center;
    border-bottom: .75px solid var(--mid);
    font-family: var(--font-h);
    font-weight: 400;
    font-style: italic;
    letter-spacing: .05;
}

.writing > .header, .info > .header {
    margin-bottom: 16px;
}

.space-between {
    display: flex;
    justify-content: space-between;
    align-content: center;
}

audio {
    filter: contrast(1.2);
    height: 32px;
    border: .5px solid var(--mid);
    opacity: 50%;
    border-radius: 100px;
    width: 100%;
}

.audio-label {
    font-family: var(--font-h);
    font-style: italic;
    padding-top: 32px;
    padding-bottom: 4px;
}


/* PROJECTS */

.proj-container {
    display: flex;
    gap: 24px;
    width: 100%;
    height: auto;
    border-bottom: .75px solid var(--mid);
}

.proj-img-container {
    display: flex;
    width: 120px;
    justify-content: center;
}

.proj-list {
    display: flex;
    flex-direction: column;
}

.proj-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 16px 0px 8px 0px;
    gap: 6px;
    justify-content: space-between;
}

.proj-title {
    font-size: 1.5rem;
    font-family: var(--font-h);
    font-weight: 400;
    font-style: italic;
    letter-spacing: .05;
    line-height: 115%;
}

.proj-year {
    margin-right: 16px;
    font-size: .75rem;
    color: var(--dark);
}

.proj-type {
    font-size: .75rem;
    align-self: baseline;
    background: linear-gradient(to right, var(--grad-right), var(--grad-left)) ;
    border-radius: 100px;
    padding: 0px 6px;
    width: max-content;
    margin-top: 6px;
}

.case-study {
    display: inline;
    border-radius: 100px;
    padding: 0px 6px;
    width: max-content;
    margin-top: 6px;
    font-size: .75rem;
    align-self: baseline;
    border: .5px solid var(--mid);
}

.pills {
    display: flex;
    gap: 4px;
}


.proj-desc {
    margin-right: 24px;
}

.proj-context {
    margin-right: 16px;
}

.cover {
    width: 100%;
    filter: blur(20px);
    border-radius: 100px;
    object-fit: cover;
}

.cover-hover {
    filter: blur(0);
    border-radius: 0;
    border-right: .5px solid var(--mid);
    object-fit: cover;
}



/* WRITING */


.writing-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

a.writing-link {
    color: var(--text);
    text-decoration: underline;
    text-decoration-color: var(--mid);
   
    font-style: italic;
}

a.writing-link:hover {
    color: var(--mid);
    cursor: pointer;
}

.writing-year {
    margin-left: 16px;
    font-size: .75rem;
    color: var(--dark);
}

/* ICONOGRAPY */

.icon {
    width: 12px;
    padding-right: 4px;
    opacity: 85%;
}

span.contact {
    display: flex;
    gap: 6px;
    align-items: center;
    padding-top: 4px;
}

.contact-link {
    text-decoration: underline;
    text-decoration-color: var(--mid);
    color: inherit;
}

.proj-header {
    display: flex;
}

.category {
    display: flex;
    align-items: center;
    text-transform: lowercase;
    font-size: .75rem;
    font-family: var(--font-body);
    font-style: normal;
    justify-content: flex-end;
}

.categories {
    justify-content: right;
    display: flex;
    gap: 12px;
}

/* LINKS AND DISPLAY */

.project-link, .no-link {
    color: inherit;
    text-decoration: none;
    display: inherit;
    width: inherit;
    gap: inherit;
}

.no-link:hover {
    cursor: default;
    color: var(--text)
}

.project-link:hover {
    background: var(--light);
    color: var(--mid);
}

.pointer:hover {
    cursor: pointer;
}

.out:hover {
    cursor: alias;
}

.none {
    display: none;
}



/* PAGES */

.page-wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-areas: 
        ". back ."
        ". content ."
    ;
    margin: 48px auto;
    gap: 16px;
    color: var(--text);
}

.page-title {
   font-family: var(--font-h);
   font-style: italic;
   font-size: 2.5rem;
   line-height: 115%;
}

.page-content {
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    grid-area: content;
    /* border: .5px solid var(--mid); */
}

.page-details {
    display: flex;
    gap: 16px;
    padding-bottom: 24px;
}

.back {
    text-decoration: none;
    border: .5px solid var(--mid);
    width: max-content;
    padding: 8px 12px;
    color: var(--text);
    display: flex;
    gap: 4px;
}

.back-button {
    grid-area: back;
}

.back:hover{
    background-color: var(--light);
}

.highlight {
    background-color: var(--light);
}

.paragraph, h3, .details, h2 {
    line-height: 150%;
    width: 75%;
    margin: auto;
}

h3 {
    font-size: 1rem;
}

h3, h2 {
    font-family: var(--font-h);
    font-style: italic;
    margin-block-end:8px;
    text-transform: uppercase;
    margin-block-start: 28px;
}

span.code {
    font-family: monospace;
    background: var(--grad-right);
    border-radius: 2px;
    font-size: .75rem;
    padding: 2px 2px;
}

.paragraph {
    margin-block-end: 12px;
}

.last-p {
    margin-block-end: 48px;
}

.intro-p {
    margin-block: 48px;
}

.details {
    display: flex;
    gap: 32px;
    margin-bottom: 24px;
}

.detail-column {
    display: block;
    width: 30%;
}

.reset-margin {
    margin-left: 0;
}

.image-wrapper {
    display: flex;
    flex-direction: column;
}

.page-image {
    width: 100%;
    margin: 24px 0 0 0;
}

.border {
    border: 1px solid var(--cs-grad-left);
}

figcaption {
    text-justify: center;
    margin: 8px auto 48px auto;
    font-size: .8rem;
}

.image-gallery {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.detail-column > ul {
    padding-inline-start:8px;
}

.column-third {
    flex-basis: 30%;
}

.column-half {
    flex-basis: 45%;
}

img {
    width: 100%;
}

.out-icon {
    display: none;
}

p.bio {
    margin-block-start: 0;
}

.iframe-container {
    height: 100%;
}

/* WEBSITE ARCHIVE */

/* .section {
    margin-bottom: 10rem;
} */

h2 {
    font-size: 1.75rem;
}

.timeline {
    display: grid;
    overflow-x: scroll;
    gap: 20px;
    grid-template-columns: auto repeat(10, 750px);
    margin-top: -48px;
    padding: 48px;
    padding-bottom: 0;
    background-color: var(--light);
    scroll-snap-type: x mandatory;
    height: calc(100vh - 48px);
    border-top: 1px solid var(--cs-grad-right);
    overflow-y: hidden;
}

.section {
    scrollbar-color: var(--light) #7d93ed);
    scrollbar-width: 0;
  }

.section::-webkit-scrollbar {
    background-color: var(--light);
    width: 0;
    height: 0;
}


.section {
    border: 1px solid var(--mid);
    border-radius: 2px;
    background-color: #f4f5f2;
    height: max-contentt;
    padding: 0 1px 48px 0;
    scroll-snap-align: center;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 48px;
}

.section > .paragraph, .section > .details {
    width: auto;
    margin: 0 36px 12px 36px;
}

ol {
    margin-bottom: 36px;
}

.section.archive-intro {
    width: 450px;
    background-color: rgb(255, 255, 0);
    margin-bottom: 48px;
    border-bottom: 1px solid var(--mid);
}

.section h2 {
    margin-left: 36px;
    margin-right: 36px;
}

@media only screen and (max-width: 900px) {
    .wrapper {
        grid-template-columns: minmax(auto, 1fr) minmax(auto, 2fr);
        grid-template-areas: 
            "info proj"
            "shea proj"
            "writing proj";
        margin: 64px;
        margin-bottom: 48px;
    }

    .timeline {
        grid-template-columns: auto repeat(11, 450px);
        padding: 36px; 
        padding-bottom: 0;  
        height: calc(100vh - 36px) 
    }

    .section.archive-intro {
        width: 300px;
        margin-bottom: 24px;
    }

    .section {
        margin-bottom: 24px;
    }
}


@media only screen and (max-width: 600px) {
    .wrapper {
        display: flex;
        flex-direction: column;
        width: auto;
        margin: 20px;
        gap: 48px;
    }

    .timeline {
        grid-template-columns: repeat(11, 98%);
        gap: 12px;
        height: calc(100vh - 24px);
        padding: 24px;
        padding-bottom: 0;
    }

    .section.archive-intro {
        width: auto;
    }

   .section {
    padding-right: -1px;
    padding-left: -1px;
   }

   .section > .paragraph, .section h2 {
        margin-left: 16px;
        margin-right: 16px;
   }

   .section > .details {
    margin: 0;
    border-radius: 0;
    background: none;
   }

   .section ul {
    padding-inline-start: 16px;
   }

   .page-wrapper {
     margin-top: 24px;
   }

    .right, .left {
        border: none;
    }

    .proj-title { 
        font-size: 1.2rem;
    }

    .proj-img-container {
        width: 80px;
    }

    .proj-context {
        display: none;
    }

    .proj-year {
        margin-right: 8px;
    }

    .categories {
        display: none;
    }

    .out-icon{ 
        margin-left: 4px;
        display: inline;
        width: 10px;
    }

    .projects {
        margin-left: 0;
    }


    /* PROJECT PAGES */

    .page-wrapper {
        display: flex;
        flex-direction: column;
        width: auto;
        /* margin: 8px; */
        gap: 16px;
        color: var(--text);
    }


    .page-content {
        border: none;
    }

    .page-header {
        line-height: 100%;
    }

    .paragraph, h3, h2 {
        width: auto;
    }

    h3, h2 {
        margin-left: 0;
        margin-block-start: 16px;
        margin-block-end: 8px;
    }

    .image-wrapper {
        margin: 12px 0px;
    }

    .details {
        flex-direction: column;
        width: auto;
        gap: 12px;
        background-color: var(--light);
        border-radius: 12px;
        padding: 12px;
        margin: 8px 0;
    }

    .detail-column {
        width: auto;
    }

    .reset-margin {
        margin-top: 4px;
    }

    .page-image {
        width: 120%;
    }

    figcaption {
        margin-bottom: 12px;
    }

    .page-title {
        line-height: 100%;
    }

    .page-details {
        gap: 4px;
        padding-bottom: 0;
    }

    .grid {
        width: 60%;
        margin: 24px auto;
    }

    .image-gallery {
        gap: 0;
        flex-wrap: nowrap;
        flex-direction: column;
    }

    .info {
        border-bottom: none;
    }

    .page-header, h3, figcaption, .page-details, .paragraph, .details, .back-button, h2 {
        margin-left: 16px;
        margin-right: 16px;
    }

    .img-2-col {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    
}

